import * as React from "react";
import { HtmlHead, Link } from "@cdv/jazz-web/components";
import { Typography, Box, Container, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";

export const Head = () => (
  <HtmlHead title="Contact | Centrum dopravního výzkumu, v. v. i.">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="Contact" lang="cs" />
    <meta name="keywords" content="contact" lang="cs" />
    <meta name="robots" content="index, follow" />
    <meta name="rating" content="general" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </HtmlHead>
);

export default function Page() {
  const aCss = {
    a: {
      textDecoration: "none",
      color: "#e05206",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
    },
    "a:hover": {
      borderBottom: "1px solid #e05206",
      transitionDuration: "0.3s",
    },
  };

  return (
    <Layout>
      <Container sx={aCss}>
        <Grid container spacing={4}>
          <Grid item md={8}>
            <iframe
              height="350"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d646.5234481146975!2d17.24308449999999!3d49.59598069999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47124ef720363421%3A0xfb8f71f5bbfc1b65!2sWellnerova+1215%2F3!5e0!3m2!1sen!2s!4v1400156009294"
              width="100%"
            ></iframe>
          </Grid>

          <Grid item md={4}>
            <strong>Transport Research Centre (CDV)</strong>
            <br />
            Wellnerova 3<br />
            779 00 Olomouc
            <br />
            Tel.: +420 541 641 795
            <br />
            Mob.: +420 602 361 748
            <br />
            Email: <Link to="mailto:gis@cdv.cz">gis@cdv.cz</Link>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}
